
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import MainMenuConfigRU from "@/core/config/MainMenuConfigRU";
import SubMenuConfig from "@/core/config/SubscriberMenuConfig";
import SubMenuConfigRU from "@/core/config/SubscriberMenuConfigRU";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import store from "@/store";
import router from "@/router";
import PartnerMenuConfig from "@/core/config/PartnerMenuConfig";

export default defineComponent({
  name: "kt-menu",
  components: {},
  setup() {
    const store = useStore();
    const { t, te } = useI18n();
    const router = useRouter();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);
    const menu = ref();

    onMounted(() => {
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }

      if (store.getters.isUserRole === "subscriber") {
        menu.value = SubMenuConfigRU;
      } else if ((store.getters.isUserRole === "partner")) {
        menu.value = PartnerMenuConfig;
      } else {
        menu.value = MainMenuConfigRU;
      }
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    const logout = () => {
      store.dispatch(Actions.LOGOUT)
        .then(() => {
          if ( store.getters.getUserRole ) {
            if ( ['administrator', 'manager', 'partner'].includes(store.getters.getUserRole) ) {
              router.push({ name: "sign-in" });
            } else {
              router.push({ name: "login" });
            }
          }
        });
    };

    return {
      hasActiveChildren,
      asideMenuIcons,
      menu,
      version,
      translate,
      logout,
    };
  },
});
