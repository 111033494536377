const SubMenuConfig = [
  {
    pages: [
      {
        heading: "Профиль",
        route: "/account/overview",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      } ,
      {
        heading: "Мои подписки",
        route: "/account/subscriptions",
        svgIcon: "media/icons/duotune/coding/cod003.svg",
        fontIcon: "bi-journal-code",
      },
      {
        heading: "Настройки",
        route: "/account/settings",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
      },
      {
        heading: "Выход",
        route: "/logout",
        svgIcon: "media/icons/duotune/finance/fin006.svg",
        fontIcon: "bi-printer",
      },
    ],
  }
];

export default SubMenuConfig;
