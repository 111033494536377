const PartnerMenuConfig = [
  {
    pages: [
      {
        heading: "Лиды",
        route: "/partner",
        svgIcon: "media/icons/duotune/communication/com005.svg",
        fontIcon: "bi-address-book",
      },
      {
        heading: "Выйти",
        route: "/logout",
        svgIcon: "media/icons/duotune/finance/fin006.svg",
        fontIcon: "bi-printer",
      },
    ],
  }
];

export default PartnerMenuConfig;
