const DocMenuConfig = [
  {
    pages: [
      {
        heading: "Главная",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Подписки",
        route: "/subscriptions",
        svgIcon: "media/icons/duotune/finance/fin006.svg",
        fontIcon: "bi-printer",
      },
      {
        heading: "Пользователи",
        route: "/users",
        svgIcon: "media/icons/duotune/general/gen025.svg",
        fontIcon: "bi-sticky",
      },
      {
        heading: "Лиды",
        route: "/leads",
        svgIcon: "media/icons/duotune/communication/com005.svg",
        fontIcon: "bi-address-book",
      },
      {
        heading: "Партнеры",
        route: "/partners",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "Словарь",
        route: "/dicionario",
        svgIcon: "media/icons/duotune/communication/com007.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "Настройки",
        route: "/settings",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
      },
      /*{
        heading: "Logs",
        route: "/logs",
        svgIcon: "media/icons/duotune/coding/cod003.svg",
        fontIcon: "bi-journal-code",
      },*/
      {
        heading: "Выйти",
        route: "/logout",
        svgIcon: "media/icons/duotune/finance/fin006.svg",
        fontIcon: "bi-printer",
      },
    ],
  }
];

export default DocMenuConfig;
